<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <div class="fill-width d-flex justify-space-between flex-wrap">
                    <div>{{ name }}</div>
                    <div class="mr-2"><v-btn text :to="{name: 'admin.creators.index'}">return</v-btn></div>
                </div>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-checkbox :value="isActive" label="Active" disabled></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field :value="moment(creator.user.verify_at).format('LLL')" label="Verified" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field :value="moment(creator.created_at).format('LLL')" label="Created" disabled></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
    import AdminDashboardLayout from "@/layouts/AdminDashboardLayout";
    import Creator from "@/models/admin/Creator";

    export default {
        name: "creators-show",
        components: {AdminDashboardLayout},
        data: function () {
            return {
                creator: false
            }
        },
        computed: {
            isActive() {
                return this.creator.is_active ? true : false
            },
            name() {
                if (this.creator) {
                    return this.creator.user.name || ''
                }
                return ''
            },
        },
        async mounted() {
            const creatorId = this.$route.params.id
            this.creator = await Creator.include('user').find(creatorId)
        },
    }
</script>

<style scoped>

</style>
